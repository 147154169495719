import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icon/iconfont.js'
import router from './router'

// import leNotes from './components/Student/letax/index.js'
// Vue.use(leNotes)

Vue.config.productionTip = false

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

Vue.use(ElementUI)
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')